
export default {
  name: 'AtomCheck',
  props: {
    id: {
      required: false,
      type: String,
      default: ''
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    name: {
      required: false,
      type: String,
      default: 'checkbox'
    },
    value: {
      required: false,
      type: Boolean,
      default: false
    },
    size: {
      required: false,
      type: Number,
      default: 22
    },
    error: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: this.$props.value
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    handleClick() {
      this.checked = !this.checked
      this.inputVal = this.checked
    },
    setStatus(status) {
      this.checked = status
      this.inputVal = this.checked
    }
  }
}
